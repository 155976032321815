// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";

window.jQuery = $;
window.$ = $;

require("bootstrap");
require("bootstrap-select");
//window.Dropzone = require("dropzone/src/dropzone");
//window.Dropzone.autoDiscover = false;

$('select').selectpicker();
$('.custom-file-input').on('change', function() {
  var fileName = this.files[0].name;
  $(this).next().text(fileName);
});

$('#rim_inch').on('changed.bs.select', function () {
  $(".rim_inch").text($("#rim_inch").selectpicker('val'));
});
